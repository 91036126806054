import Api from '@/services/Api'

export default {
  create(params) {
    return Api().post('/api/recommendCodes', params)
  },

  list(params) {
    return Api().get('/api/recommendCodes', {
      params: params
    })
  },
  update(id, params) {
    return Api().put('/api/recommendCodes/' + id, params)
  },
  delete(id) {
    return Api().delete('/api/recommendCodes/' + id)
  }
}
