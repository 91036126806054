import Api from '@/services/Api'

export default {

  distributorList(params) {
    return Api().get('/api/sales/distributors', {
      params: params
    })
  }
}
